<template>
  <b-card
    no-body
    class="p-2"
  >
    <h3 class="mb-2">
      {{
        area.areaId == 0
          ? $t("Routes.AreaAdd")
          : $t("Routes.AreaEdit")
      }}
    </h3>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- name work area field -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('organizationChart.area.columns.name')"
              rules="required"
            >
              <b-form-group
                :label="$t('organizationChart.area.columns.name')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-input v-model="area.nombre" />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('organizationChart.area.columns.hierarchicalLevel')"
              rules="required"
            >
              <b-form-group
                :label="$t('organizationChart.area.columns.hierarchicalLevel')"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="Puesto"
                  v-model="area.nivelJerarquicoId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="texto"
                  :options="hierarchicalLevels"
                  :reduce="(option) => option.id"
                  :clearable="false"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('organizationChart.area.columns.areaDepends')"
            >
              <b-form-group
                :label="$t('organizationChart.area.columns.areaDepends')"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="Puesto"
                  v-model="area.areaDependeDeId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="nombre"
                  :options="areas"
                  :reduce="(option) => option.areaId"
                  @search="searchAreas"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('organizationChart.area.columns.order')"
            >
              <b-form-group
                :label="$t('organizationChart.area.columns.order')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-input
                  v-model="area.orden"
                  type="number"
                />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- <b-col cols="12" md="6">
            <validation-provider
              #default="{ errors }"
              :name="$t('organizationChart.area.columns.index')"
            >
              <b-form-group
                :label="$t('organizationChart.area.columns.index')"
                :state="errors.length > 0 ? false : null"
              >
                <b-form-input v-model="area.indice" />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col> -->
          <!-- Description Field -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required"
            >
              <b-form-group
                :label="
                  $t('organizationChart.area.columns.description')
                "
                :state="errors.length > 0 ? false : null"
              >
                <b-form-textarea v-model="area.descripcion" />
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="float-right mt-2 mb-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
          >
            <b-spinner v-if="addingArea" small class="mr-1" />
            {{
              area.areaId == 0
                ? $t("Lists.Add")
                : $t("Lists.Edit")
            }}
          </b-button>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BSpinner,
  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import organizationChart from '@/services/organizationchart.service'
import optionService from '@/services/option.service'
import { ref } from '@vue/composition-api'
import router from '@/router'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormInvalidFeedback,
    BFormGroup,
    BFormTextarea,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: {
    modalCall: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const addingArea = ref(false)
    const hierarchicalLevels = ref([])
    const areas = ref([])
    const area = ref({
      areaId: 0,
      nivelJerarquicoId: 1,
      areaDependeDeId: null,
      nombre: '',
      descripcion: '',
      orden: null,
      indice: null,
      archivado: false,
    })

    const blankArea = {
      areaId: 0,
      nivelJerarquicoId: 1,
      areaDependeDeId: null,
      nombre: '',
      descripcion: '',
      orden: null,
      indice: null,
      archivado: false,
    }

    const {
      fetchArea,
      createArea,
      updateArea,
      fetchAreas,
    } = organizationChart()

    const {
      fetchOptions,
    } = optionService()

    const resetArea = () => {
      area.value = JSON.parse(JSON.stringify(blankArea))
    }

    if (router.currentRoute.params.areaId) {
      fetchArea(router.currentRoute.params.areaId, data => {
        area.value = data
      })
    } else {
      area.value = JSON.parse(JSON.stringify(blankArea))
    }
    fetchOptions({ nombreDominio: 'OrgnigramaNivelJerarquico' }, data => {
      hierarchicalLevels.value = data
    })
    const searchAreas = text => {
      fetchAreas({ searchQuery: text, limit: 50 }, data => {
        areas.value = data.areas
      })
    }
    fetchAreas({ limit: 50 }, data => {
      areas.value = data.areas
    })
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetArea)

    const onSubmit = () => {
      addingArea.value = true
      if (area.value.areaId === 0) {
        createArea(area.value, () => {
          addingArea.value = false
          if (props.modalCall) {
            emit('find-records')
          } else {
            router.push({ name: 'apps-organization-chart-area-list' })
          }
        })
      } else {
        updateArea(area.value.areaId, area.value, () => {
          addingArea.value = false
          router.push({ name: 'apps-organization-chart-area-list' })
        })
      }
    }

    return {
      hierarchicalLevels,
      areas,
      area,
      addingArea,
      required,
      getValidationState,
      refFormObserver,
      resetForm,
      onSubmit,
      searchAreas,
    }
  },
}
</script>
